import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import Vant from 'vant';
import 'vant/lib/index.css';
import { Lazyload } from 'vant';
import 'element-ui/lib/theme-chalk/index.css';
import animated from 'animate.css'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(animated)
Vue.use(Vant)
Vue.use(Lazyload)
function isMobile () {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    ) return true;
    return false;
}
router.beforeEach((to, from, next) => {
    // eslint-disable-next-line no-console
    console.log(to, '_______', from, '_______', next)
    let res = isMobile()
    // 手机端
    if (res) {
        if (to.fullPath === '/home') {
            next({
                path: '/mhome'
            })
        } else if (to.fullPath === '/about') {
            next({
                path: '/mabout'
            })
        } else if (to.fullPath === '/news') {
            next({
                path: '/mnews'
            })
        }
        next()
    } else {
        if (to.fullPath === '/mhome') {
            next({
                path: '/home'
            })
        } else if (to.fullPath === '/mabout') {
            next({
                path: '/about'
            })
        } else if (to.fullPath === '/mnews') {
            next({
                path: '/news'
            })
        }
        // 电脑端
        next()
    }
});

router.afterEach(() => {
    window.scroll(0, 0);
});

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')

import { render, staticRenderFns } from "./App.vue?vue&type=template&id=08a48600&"
var script = {}
import style0 from "./App.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/registry.nlark.com+vue-loader@15.9.7_css-loader@3.6.0+webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/About.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/News.vue')
    },
    {
        path: '/table',
        name: 'table',
        component: () => import('../views/Table.vue')
    },
    {
        path: '/mhome',
        name: 'mhome',
        component: () => import('../views/MobileHome.vue')
    },
    {
        path: '/mnews',
        name: 'mnews',
        component: () => import('../views/MobileNews.vue')
    },
    {
        path: '/mabout',
        name: 'mabout',
        component: () => import('../views/MobileAbout.vue')
    }
]


const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
